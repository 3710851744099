import React, {
  createContext,
  forwardRef,
  useMemo,
  useCallback,
  useState,
  useContext,
} from 'react'

import {
  Dialog as TDialog,
  withStaticProperties,
  DialogProps,
  styled,
  TamaguiElement,
  Button,
  Unspaced,
  getToken,
  ScrollView,
  useSheet,
  useSheetController,
  YStack,
} from 'tamagui'
import { H2, Paragraph } from '../Text'

import DismissIcon from '../../components/GuildIcon/fluentIcons/dismiss'

import './Dialog.css'

const OriginalPortal = TDialog.Portal
const OriginalOverlay = TDialog.Overlay

export const DialogContext = createContext<{
  open: boolean | undefined
  setOpen: (newOpen: boolean) => void
}>({
  open: undefined,
  setOpen: () => undefined,
})

const DDialog = forwardRef<{ open: (val: boolean) => void }, DialogProps>(
  (props: DialogProps, ref) => {
    const [isOpen, setIsOpen] = useState(props.open || false)

    const onOpenChange = useCallback(
      (open: boolean) => {
        // console.log('ON OPEN CHANGE', open)
        // console.trace()

        if (props.onOpenChange) {
          props.onOpenChange(open)
        }

        setIsOpen(open)
      },
      [props.onOpenChange, setIsOpen]
    )

    const contextValue = useMemo(() => {
      return {
        open: isOpen,
        setOpen: setIsOpen,
      }
    }, [isOpen, setIsOpen])

    return (
      <DialogContext.Provider value={contextValue}>
        <TDialog
          key='dialog'
          modal
          {...props}
          onOpenChange={onOpenChange}
          ref={ref}
        >
          {props.children}
        </TDialog>
      </DialogContext.Provider>
    )
  }
)

const DialogPortal = React.forwardRef(
  ({ children, ...props }: { children: React.ReactNode }, ref) => {
    const { open } = useContext(DialogContext)

    // const sheetController = useSheetController()

    return (
      <OriginalPortal
        key='portal'
        {...props}
        className={
          open ? 'new_Dialog new_Dialog_enter' : 'new_Dialog new_Dialog_exit'
        }
        // display={sheetController.isHidden ? 'flex' : 'none'}
        ref={ref}
      >
        <OriginalOverlay
          key='overlay'
          animation='fast'
          backgroundColor='$grey7'
          style={{ opacity: 0.2 }}
          enterStyle={{ opacity: 0 }}
          exitStyle={{ opacity: 0 }}
          // display={sheetController.isHidden ? 'flex' : 'none'}
        />

        {children}
      </OriginalPortal>
    )
  }
)

const DialogSheet = React.forwardRef(
  ({ children, ...props }: { children: React.ReactNode }, ref) => {
    const { open } = useContext(DialogContext)

    return (
      <TDialog.Sheet
        key='sheet'
        {...props}
        portalProps={{
          className: open
            ? 'new_Dialog new_Dialog_enter'
            : 'new_Dialog new_Dialog_exit',
          opacity: open ? 1 : 0,
        }}
        ref={ref}
        zIndex={100_000}
        animation='fastSpring'
        modal
        snapPoints={[85, 50, 35]}
        snapPointsMode='percent'
        dismissOnSnapToBottom
        unmountChildrenWhenHidden
      >
        <TDialog.Sheet.Overlay
          key='overlay'
          animation='medium'
          backgroundColor='$grey7'
          opacity={1}
          enterStyle={{ opacity: 0 }}
          exitStyle={{ opacity: 0 }}
          style={{ opacity: 0.2 }}
        />

        {children}
      </TDialog.Sheet>
    )
  }
)

const StyledDialogContent = styled(TDialog.Content, {
  acceptsClassName: true,

  borderRadius: '$6',
  padding: 0,
  backgroundColor: '$grey0',

  animation: 'mediumSpring',
  x: 0,
  y: 0,
  rotateX: '0deg',

  opacity: 1,
  scale: 1,
  enterStyle: {
    x: 0,
    y: -100,
    rotateX: '-45deg',
    opacity: 0,
    scale: 1.2,
  },
  exitStyle: {
    x: 0,
    y: 50,
    opacity: 0,
    scale: 0.9,
    rotateX: '-20deg',
  },

  style: {
    boxShadow:
      'rgba(27, 19, 64, 0.5) 0px 4px 8px -4px, rgba(27, 19, 64, 0.3) 0px 5px 26px -7px',
    // transform: `translateZ(0)`,
    willChange: 'transform',
  },

  maxHeight:
    'calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom) - 20px)',

  // shadowRadius: '$2',
  // shadowColor: '$grey6',
})

const StyledCloseButton = styled(DismissIcon, {
  name: 'DialogCloseButton',

  // shadowColor: 'rgba(10, 37, 64, 0.18)',
  // shadowRadius: '$2',
  // shadowOffset: { width: 0, height: 2 },

  acceptsClassName: true,

  backgroundColor: '$grey0',
  borderWidth: 0,
  width: '$5',
  height: '$5',
  position: 'absolute',
  top: -4,
  right: -4,
  padding: '$1',
  borderRadius: '50%',
  cursor: 'pointer',
  // animation: 'medium',
  // animateOnly: ['background-color'],

  hoverStyle: {
    backgroundColor: '$grey3',
  },
  // style: {
  //   boxShadow:
  //     'rgba(10, 37, 64, 0.12) 0px 4px 8px -4px, rgba(10, 37, 64, 0.09) 0px 5px 26px -7px',
  // },
})

export const Dialog = withStaticProperties(DDialog, {
  Portal: DialogPortal,
  Trigger: TDialog.Trigger,
  Content: StyledDialogContent.styleable(({ children, ...props }, ref) => {
    const sheetController = useSheetController()

    return (
      <StyledDialogContent
        key='content'
        {...props}
        ref={ref}
        onInteractOutside={(event) => {
          // Taken from: https://github.com/radix-vue/shadcn-vue/issues/107#issuecomment-1978420200
          const target = event.target as HTMLElement

          const isGrammarly =
            target && target.hasAttribute('data-grammarly-shadow-root')

          const is1Password = target.tagName === 'COM-1PASSWORD-BUTTON'

          if (isGrammarly || is1Password) {
            event.preventDefault()
          }
        }}
      >
        {sheetController.isHidden ? (
          <YStack
            flex={props.flex}
            gap={props.gap}
            padding={props.padding ?? '$5'}
            borderRadius='$6'
            style={{ overflowY: 'auto' }}
            minHeight='100%'
            maxHeight='100%'
            minWidth='100%'
            alignItems={props.alignItems}
          >
            {children}
          </YStack>
        ) : (
          children
        )}

        <Unspaced>
          <TDialog.Close asChild>
            <StyledCloseButton size={4} />
          </TDialog.Close>
        </Unspaced>
      </StyledDialogContent>
    )
  }),

  Title: styled(TDialog.Title, {
    name: 'NewDialogTitle',
    tag: 'h2',
    role: 'heading',
    fontWeight: '$6',
    size: '$6',
    style: {
      WebkitFontSmoothing: 'antialiased',
    },
  } as const),
  Description: styled(TDialog.Description, {
    tag: 'p',
    name: 'NewDialogDescription',
    fontWeight: '$4',

    variants: {
      size: {
        '...size': (size) => {
          return {
            fontSize: size,
            lineHeight: size,
          }
        },
      },
      marginAfter: {
        '...': (lineHeight, config) => {
          return {
            marginBottom:
              config.font?.lineHeight[lineHeight].variable ?? lineHeight,
          }
        },
      },
    } as const,

    defaultVariants: {
      size: '$4',
    },
  } as const),
  Close: TDialog.Close,
  Adapt: TDialog.Adapt,

  Sheet: withStaticProperties(DialogSheet, {
    Overlay: TDialog.Sheet.Overlay,
    Frame: TDialog.Sheet.Frame,
    Handle: TDialog.Sheet.Handle,
    ScrollView: styled(TDialog.Sheet.ScrollView, {
      paddingBottom: 'calc(env(safe-area-inset-bottom, 20px) + 20px)',
    }),
  }),
})
